<template>
  <div>
    <my-header></my-header>
    <div class="box">
      <div>
        <span>班组管理</span>
        <span @click="lookgroups" class="lookgroups">查看所有班组</span>
      </div>

      <div class="box-form">
        <el-form
          v-if="ruleForm"
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm"
        >
          <el-form-item label="班组名称" prop="Name">
            <el-input v-model="ruleForm.Name"></el-input>
          </el-form-item>
          <el-form-item label="组长电话" prop="phone">
            <el-input v-model="ruleForm.phone" disabled></el-input>
          </el-form-item>
          <el-form-item label="班组人数" prop="Num">
            <el-input v-model="ruleForm.Num"></el-input>
          </el-form-item>
          <el-form-item label="班组类型" prop="GroupType">
            <el-radio-group
              v-model="ruleForm.GroupType"
              v-for="(item, index) in TypeList"
              :key="index"
            >
              <el-radio class="type" :label="item.name"></el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="所在城市" prop="address">
            <el-cascader
              v-model="ruleForm.address"
              :options="allAreaData"
              @change="handleChange"
            >
            </el-cascader>
          </el-form-item>

          <el-form-item label="班组介绍" prop="Notice">
            <el-input type="textarea" v-model="ruleForm.Notice"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              v-if="!modification"
              @click="submitForm('ruleForm')"
              >提交</el-button
            >
            <el-button
              type="primary"
              v-if="modification"
              @click="ReForm('ruleForm')"
              >提交修改</el-button
            >
            <el-button @click="resetForm('ruleForm')">重置</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  inject: ["reload"],
  data() {
    return {
      SubmitGrouplist:[],
      id:'',
      modification: false,
      userlist: [],
      //地址
      allAreaData: [],
      //类型列表
      TypeList: [],
      ruleForm: {
        address: [],
        Name: "",
        phone: "",
        GroupType: "",
        Notice: "",
        Num: "",
      },
      rules: {
        Name: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        Num: [{ required: true, message: "请输入人数", trigger: "blur" }],
        phone: [{ required: true, message: "请输入电话", trigger: "blur" }],
        address: [{ required: true, message: "请选择省市区", trigger: "blur" }],
        GroupType: [
          { required: true, message: "请选择班组类型", trigger: "change" },
        ],
        Notice: [{ required: true, message: "请填写简介", trigger: "blur" }],
      },
    };
  },

  methods: {
    //获取用户信息
    userinfo() {
      let userlist = JSON.parse(sessionStorage.getItem("userinfo"));
      console.log(userlist);
      this.ruleForm.phone = userlist.phone,
      this.userlist = userlist;
      if (userlist.ifcheck == 0) {
        this.open();
      }
    },
    //获取类型列表
    async getType() {
      const res = await this.$http({
        method: "GET",
        url: "front/workType/getTypeList?type=0",
      });
      this.TypeList = res.data.data;
      console.log(this.TypeList, "类型");
    },
    //修改按钮
    resubmit(){
       if(JSON.parse(sessionStorage.getItem("roleId"))==11){
         this.modification=true
       }
    },
    //提交
    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          console.log(this.ruleForm);
          let ruleForm = this.ruleForm;
          const res = await this.$http({
            method: "POST",
            url: `front/groupWork/addGroup`,
            data: {
              groupName: ruleForm.Name,
              groupType: ruleForm.GroupType,
              provinceId: ruleForm.address[0],
              cityId: ruleForm.address[1],
              countyId: ruleForm.address[2],
              groupNotice: ruleForm.Notice,
              groupNum: ruleForm.Num,
            },
          });
          if (res.status == 200) {
            this.$message({
              type: "success",
              message: "添加成功",
            });
          }
          this.logOut();
          this.$message.error("请重新登录。");
          this.$router.push({ path: "/index" });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //提交修改
    ReForm(formName){
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          console.log(this.ruleForm);
          let ruleForm = this.ruleForm;
          const res = await this.$http({
            method: "POST",
            url: `/front/groupWork/updateGroup`,
            data: {
              id:this.id,
              groupName: ruleForm.Name,
              groupType: ruleForm.GroupType,
              provinceId: ruleForm.address[0],
              cityId: ruleForm.address[1],
              countyId: ruleForm.address[2],
              groupNotice: ruleForm.Notice,
              groupNum: ruleForm.Num,
            },
          });
          if (res.status == 200) {
            this.$message({
              type: "success",
              message: "修改成功",
            });
          }
          location.reload()
          // this.logOut();
          // this.$message.error("请重新登录。");
          // this.$router.push({ path: "/index" });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    //查看已提交
    async lookgroups() {
      const res = await this.$http({
        method: "get",
        url: "/front/groupWork/findList?orderBy=create_time desc",
      });
      if (res.data.status == 200) {
        console.log(res.data.data.list);
        this.SubmitGrouplist = res.data.data.list;
        // this.lookdialogTableVisible = true;
        this.$router.push({
          path: "/Groupworklist",
          query: {
            item: JSON.stringify(this.SubmitGrouplist),
          },
        });
      }
    },
    

    handleChange(value) {
      console.log(value);
    },
    //获取全部地址
    // async getallAreaData() {
    //     const AreaData = JSON.parse(sessionStorage.getItem("allAreaData"));

    //     this.allAreaData = AreaData;

    // },
    //是否实名制
    open() {
      this.$confirm("您没有进行实名认证请先进行实名认证", "提示", {
        confirmButtonText: "前往个人中心认证",
        cancelButtonText: "返回首页",
        type: "warning",
      })
        .then(() => {
          this.$message({
            type: "success",
            message: "前往",
          });
          this.$router.push({ path: "/myCenter" });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "返回",
          });
          this.$router.push({ path: "/index" });
        });
    },
    //获取班组信息
    async getgroup() {
      const res = await this.$http({
        method: "get",
        url: `/front/groupWork/findList?userId=${this.userlist.id}`,
        
      });
       const allAreaData = JSON.parse(sessionStorage.getItem("allAreaData"));
        this.allAreaData = allAreaData;
      if (res.data.data.list[0]) {
        let provinceData = {};
        let cityData = {};
        let districtData = {};
        console.log(res.data.data.list[0], "单一组"),
          console.log(allAreaData, "总体");
        allAreaData.forEach((item, index) => {
          // console.log(res.data.data.list[0].areaName)
          if (item.label == res.data.data.list[0].areaName[0]) {
            provinceData = item;
          }
        });
        provinceData.children.forEach((item, index) => {
          if (item.label == res.data.data.list[0].areaName[1]) {
            cityData = item;
          }
        });
        cityData.children.forEach((item, index) => {
          if (item.label == res.data.data.list[0].areaName[2]) {
            districtData = item;
          }
        });
        this.id=res.data.data.list[0].id
        this.ruleForm.address.push(provinceData.value);
        this.ruleForm.address.push(cityData.value);
        this.ruleForm.address.push(districtData.value);
        console.log(this.ruleForm.address);
        this.ruleForm.Name = res.data.data.list[0].groupName,
        
        this.ruleForm.GroupType = res.data.data.list[0].groupType,
        this.ruleForm.Notice = res.data.data.list[0].groupNotice,
        this.ruleForm.Num = res.data.data.list[0].groupNum
      }
    },

    //登出
    async logOut() {
      const res = await this.$http({
        method: "post",
        url: "front/user/logout",
      });
      console.log(res);
      sessionStorage.setItem("roleId", "null");
      if (res.status == 200) {
        this.$message({
          message: "已退出登录",
          type: "error",
        });
        sessionStorage.clear();
        location.reload();
        this.userinfo = "";
      }
    },
  },
  created() {
    this.userinfo();
    this.getgroup();
    this.getType();
    this.resubmit()
  },
};
</script>

<style lang="scss" scoped>
.box {
  width: 100%;
  margin-top: 150px;
  & > div:nth-child(1) {
    background: #f5f5f6;
    height: 150px;
    margin: 0 auto;
    font-size: 36px;
    color: #101d37;
    font-weight: 700;
    text-align: center;
    line-height: 120px;
    position: relative;
    color: #101d37 !important;
    & > .lookgroups {
      position: absolute;
      right: 150px;
      top: 50px;
      height: 30px;
      color: #5079d9;
      line-height: 0px;
      cursor: pointer;
      font-size: 18px;
      font-weight: 700;
    }
  }
  .box-form {
    padding: 20px;
    width: 70%;
    margin: 0 auto;
  }
  & ::v-deep .el-progress__text {
    display: none;
  }
}
.type {
  margin-right: 10px;
  & > :last-child {
    padding-left: 2px;
  }
}
</style>
